import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import classnames from "classnames";

import { DndContext } from "@dnd-kit/core";
// import {Draggable} from './Draggable';
// import {Droppable} from './Droppable';
import { useDroppable, useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { Button } from "../../ui/components/buttons/base/index.jsx";
import {
  VisualPicker,
  Option,
} from "../../ui/components/visual-picker/coverable-content/example.jsx";
import DndSpike from "../DndSpike/index.jsx";

const StyledGrid = styled.div`
  .slds-col {
    border: 0.25rem solid gray;

    & + .slds-col {
      border-left-width: 0;
    }
    opacity: 0;
  }

  .proto-drop-zone {
    width: 90%;
    height: 90%;
  }
`;

const ThumbButton = styled.a`
  background: none;
  border: 2px solid blue;
  border-radius: 0.25rem;

  &.proto-floating {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const BuilderPrototyper = (props) => {
  return (
    <StyledGrid className="slds-grid proto-body">
      <div className="proto-left-col slds-col slds-grow-none slds-is-relative ">
        {/* <ThumbButton className="proto-floating" href="show-variants.html"></ThumbButton> */}
      </div>
      <div className="proto-center-col slds-col">
      </div>
      <div className="proto-right-col slds-col slds-grow-none"></div>
    </StyledGrid>
  );
};

export default BuilderPrototyper;
