import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import classnames from "classnames";

import { DndContext } from "@dnd-kit/core";
// import {Draggable} from './Draggable';
// import {Droppable} from './Droppable';
import { useDroppable, useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { Button } from "../../ui/components/buttons/base/index.jsx";
import {
  VisualPicker,
  Option,
} from "../../ui/components/visual-picker/coverable-content/example.jsx";

const DraggerDiv = styled.div`
  top: 413px;
  left: 49px;
`;

const DropperDiv = styled.div`
  width: 140px;
  height: 42px;
  &.proto-drop-zone-dropped {
    width: auto;
  }
`;

const DroppedSlotDiv = styled.div`
  &.proto-has-warning {
    outline: 2px solid #ffb75d;
    outline-offset: 0.125rem;
  }
`;

const ButtonSlotDiv = styled.div`
  top: 749px;
  left: 713px;
  width: 540px;
  height: 42px;
  display: flex;
  justify-content: flex-end;
`;

const DraggableItem = styled.div`
  transform: scale(1.33);
`;

const WarningPopover = styled.div`
  position: absolute;
  top: 473px;
  left: 1149px;
  width: 318px;
  height: 272px;
  background: url(/assets/hax/warning-popover.png) no-repeat;
  background-size: 100%;
`;

const Droppable = ({ children, hasDropped, id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  const style = {
    opacity: hasDropped ? 1 : isOver ? 0.5 : 0,
  };

  return (
    <DropperDiv
      ref={setNodeRef}
      style={style}
      className={classnames("slds-m-left_small", {
        "slds-align_absolute-center slds-drop-zone slds-m-around_none": !hasDropped,
        "proto-drop-zone": true,
        "proto-drop-zone-dropped": hasDropped,
        "slds-drop-zone_drag": isOver,
        "slds-drop-zone": hasDropped && isOver
      })}
    >
      {isOver ? <div className="slds-drop-zone_drag__slot"></div> : children}
    </DropperDiv>
  );
};

const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  };

  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </button>
  );
};

const DndButtons = (props) => {
  const [droppedComponents, setDroppedComponents] = useState({});
  const [isDropped, setIsDropped] = useState(null);
  const [isDragging, setIsDragging] = useState(null);
  const [hasWarning, setHasWarning] = useState(false);

  useLayoutEffect(() => {
    console.log(
      "hasWarning",
      Object.values(droppedComponents).every((value) => value.includes("brand"))
    );
    setHasWarning(
      Object.values(droppedComponents).length > 1 &&
        Object.values(droppedComponents).every((value) =>
          value.includes("brand")
        )
    );
  }, [droppedComponents]);

  const handleDragStart = ({ active, ...props }) => {
    console.log(active, props);
    setIsDragging(active ? active.id : null);
  };

  const handleDragEnd = ({ over, active, ...props }) => {
    console.log({ ...droppedComponents, ...{ [over.id]: active.id } });
    setIsDropped(over ? over.id : null);
    setDroppedComponents({ ...droppedComponents, ...{ [over.id]: active.id } });
  };

  const TheDraggable = ({ children, id }) => (
    <Draggable key={id} id={`draggable-${id}`}>
      <DraggableItem>{children}</DraggableItem>
    </Draggable>
  );

  const DroppedButton = ({ children, id }) => (
    <Button
      isBrand={id.includes("brand")}
      isOutlineBrand={id.includes("outline")}
    >
      {children}
    </Button>
  );

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <ButtonSlotDiv className="slds-is-absolute">
        {[1, 2].map((dropNum) => (
          <Droppable
            id={`droppable-${dropNum}`}
            key={`droppable-${dropNum}`}
            hasDropped={droppedComponents[`droppable-${dropNum}`]}
          >
            {!droppedComponents[`droppable-${dropNum}`] ? (
              <span className={`droppable-${dropNum}`}></span>
            ) : (
              <DroppedSlotDiv
                className={classnames({ "proto-has-warning": hasWarning })}
              >
                <DroppedButton id={droppedComponents[`droppable-${dropNum}`]}>
                  {dropNum === 1 ? "View All Shoes" : "Show Comparisons"}
                </DroppedButton>
              </DroppedSlotDiv>
            )}
          </Droppable>
        ))}{" "}
      </ButtonSlotDiv>

      <DraggerDiv className="slds-is-absolute">
        <TheDraggable id="b-brand">
          <Button isBrand>Brand</Button>
        </TheDraggable>
      </DraggerDiv>
      <DraggerDiv className="slds-is-absolute">
        <TheDraggable id="a-brand">
          <Button isBrand>Brand</Button>
        </TheDraggable>
      </DraggerDiv>
      <DraggerDiv className="slds-is-absolute" style={{ top: `888px` }}>
        <TheDraggable id="c-outline">
          <Button isOutlineBrand>Outline</Button>
        </TheDraggable>
      </DraggerDiv>
      {hasWarning && <WarningPopover />}
    </DndContext>
  );
};

export default DndButtons;
