import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import classnames from "classnames";

import { DndContext } from "@dnd-kit/core";
// import {Draggable} from './Draggable';
// import {Droppable} from './Droppable';
import { useDroppable, useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { Button } from "../../ui/components/buttons/base/index.jsx";
import {
  VisualPicker,
  Option,
} from "../../ui/components/visual-picker/coverable-content/example.jsx";

const StyledGrid = styled.div`
  .slds-col {
    border: 0.25rem solid gray;

    & + .slds-col {
      border-left-width: 0;
    }
  }

  .proto-drop-zone {
    width: 90%;
    height: 90%;
  }
`;

const Droppable = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    opacity: isOver ? 1 : 0.5,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classnames("slds-align_absolute-center slds-drop-zone", {
        "proto-drop-zone": true,
      })}
    >
      {props.children}
    </div>
  );
};

const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  };

  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </button>
  );
};

const DndSpike = (props) => {
  const [parent, setParent] = useState(null);

  const handleDragEnd = ({ over }) => {
    setParent(over ? over.id : null);
  };

  const TheDraggable = ({ id }) => (
    <Draggable key={id} id={`draggable-${id}`}>
      <VisualPicker
        type="radio"
        icon
        sprite="utility"
        symbol="connected_apps"
        label={<Option label={id} />}
        size="small"
      />
    </Draggable>
  );

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <StyledGrid className="slds-grid">
        <div className="slds-col slds-size_1-of-4">
          {[
            "connected_apps",
            "connected_apps",
            "connected_apps",
            "connected_apps",
          ].map((type, idx) => (
            <div> {!parent ? <TheDraggable idx={idx} /> : null}</div>
          ))}
        </div>
        <div className="slds-col slds-size_3-of-4 slds-grid ">
          <Droppable id="droppable">
            {parent === "droppable" ? draggable : <span>DROP HERE!</span>}
          </Droppable>
        </div>
      </StyledGrid>
    </DndContext>
  );
};

export default DndSpike;
