import React from "react";
import ReactDOM from "react-dom";
import BuilderPrototyper from "./components/BuilderPrototyper/index.jsx";
import DndButtons from "./components/DndButtons/index.jsx";
class App extends React.Component {
  render() {
    return <BuilderPrototyper />;
  }
}
class DemoAddButtons extends React.Component {
  render() {
    return <DndButtons />;
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

if (document.getElementById("root-add-buttons")) {
  const rootElement = document.getElementById("root-add-buttons");
  ReactDOM.render(<DemoAddButtons />, rootElement);
}
